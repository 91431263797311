import React, {useContext,useState, useEffect} from "react";
import {navigate} from "gatsby";
import {CartContext} from '../cart';

import {CompactButton} from "../components/button";

const Cart = () => {
    const { clearCart, checkout } = useContext(CartContext)
    
    useEffect(() => {
      setTimeout(() => {
        clearCart();
      }, 3000);
    }, []);

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    maxWidth: 800,
                    margin: '96px auto',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <div>
                    下訂成功
                </div>
                <div>
                    我們會盡快為您準備
                </div>
                <div style={{ height: 24}} />
                <CompactButton
                    onClick={() => {
                        clearCart();
                    }
                    }
                >
                    返回主頁
                </CompactButton>
            </div>
        </div>
    );
};

export default Cart;
